<template>
  <div>
    <div
      class="justify-space-between navbar align-center flex-wrap flex-md-nowrap"
      id="navbar"
      style="background-color: #212121"
    >
      <header style="min-height: 64px; display: flex; align-items: center">
        MineTown
      </header>
      <section class="menu d-md-none" @click="show($data)">
        <v-icon>mdi-menu</v-icon>
      </section>
      <v-expand-transition>
        <section class="nav-links" v-if="menu || windowWidth > 959">
          <router-link to="/" active-class="active" exact>Главная</router-link>
          <router-link to="/rules" active-class="active" exact
            >Правила</router-link
          >
          <router-link to="/store" active-class="active" exact
            >Магазин</router-link
          >
          <router-link to="/map" active-class="active" exact>Карта</router-link>
          <router-link to="/help" active-class="active" exact
            >Помощь</router-link
          >
        </section>
      </v-expand-transition>
      <section class="d-none d-md-inline-block" style="display: none">
        <v-btn color="primary" elevation="2" id="play" to="/join"
          >Играть сейчас</v-btn
        >
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    show: data => {
      data.menu = !data.menu;
    }
  },
  created() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  data: () => {
    return {
      menu: false,
      windowWidth: window.innerWidth
    };
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  padding: 0 20px;
}

.nav-links a {
  text-decoration: none;
  color: #989898;
  display: block;
  height: 100%;
  padding: 20px 20px;
  transition: background-color ease-in-out 250ms;
}

.nav-links a.active {
  border-bottom: 2px solid #f7931a;
  border-radius: 2px 2px;
  color: #f7931a;
}

.nav-links a:hover {
  color: #e7e7e7;
  background-color: rgba(159, 159, 159, 0.05);
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

header {
  font-size: 24px;
}

.menu {
  cursor: pointer;
}

@media screen and (max-width: 959px) {
  .nav-links {
    width: 100%;
    height: unset;
    flex-direction: column;
    padding-bottom: 32px;
  }

  .nav-links a {
    width: 100%;
    text-align: center;
  }
}
</style>
