<template>
  <footer class="footer d-flex justify-space-between flex-column flex-md-row text-center text-md-start">
    <div class="footer-block">
      <h4>MineTown</h4>
      <router-link to="/help" class="footer-link" exact>Контакты</router-link>
      <router-link to="/rules" class="footer-link" exact>Правила</router-link>
    </div>
    <div class="grey--text text-center text-md-end">
      <div>Copyright © MineTown 2021.</div>
      <div>Все права защищены.</div>
      <p>Проект MineTown никак не относится к Mojang, AB.</p>
      <a href="https://freekassa.ru" title="big-dark-1"><img src="https://cdn.freekassa.ru/banners/big-dark-1.png" alt="big-dark-1" /></a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  padding: 30px 100px 0;
  background-color: #1d1d1d;
}

.footer-link {
  color: #989898;
  text-decoration: none;
  display: block;
  transition: color ease-in-out 250ms;
}

.footer-link:hover {
  color: #f7931a;
}

.footer-block {
  max-width: 200px;
  display: inline-block
}

@media screen and (max-width: 959px) {
  .footer-block {
    max-width: unset;
    margin-bottom: 1em;
  }
}
</style>