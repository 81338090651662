import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import moment from 'moment';

Vue.use(VueRouter);

moment.locale("ru");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/rules",
    name: "Rules",
    component: () =>
      import(/* webpackChunkName: "static" */ "../views/pages/Rules.vue")
  },
  {
    path: "/store/cart",
    name: "Cart",
    component: () => import(/* webpackChunkName: "store" */ "../views/store/Cart.vue")
  },
  {
    path: "/store",
    name: "Store",
    component: () =>
      import(/* webpackChunkName: "store" */ "../views/store/Store.vue")
  },
  {
    path: "/minecraft",
    name: "Minecraft",
    component: () =>
      import(/* webpackChunkName: "static" */ "../views/pages/Minecraft.vue")
  },
  {
    path: "/join",
    name: "Join",
    component: () =>
      import(/* webpackChunkName: "static" */ "../views/pages/Join.vue")
  },
  {
    path: "/map",
    name: "Map",
    component: () => import(/* webpackChunkName: "map" */ "../views/pages/Map.vue")
  },
  {
    path: "/help",
    name: "Help",
    component: () => import(/* webpackChunkName: "help" */ "../views/pages/Help.vue")
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router;
