<template>
  <div class="scroll-fab" v-if="show">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-fade-transition>
          <v-btn
              elevation="7"
              fab
              large
              fixed
              bottom
              right
              v-bind="attrs"
              v-on="on"

              @click="scroll"
          ><v-icon color="primary">mdi-arrow-up</v-icon></v-btn
          >
        </v-fade-transition>
      </template>
      <span>Выход на землю</span>
    </v-tooltip>
  </div>
</template>

<script>
import { scrollToTop } from "@/lib/utils";

export default {
  name: "ScrollFab",
  data() {
    return {
      show: false
    };
  },
  mounted() {
    let app = this;
    document.addEventListener("scroll", function () {
      app.show =
          (document.documentElement.scrollTop || document.body.scrollTop) > 0;
    });
  },
  methods: {
    scroll: scrollToTop
  }
};
</script>

<style scoped></style>
