<template>
  <v-app>
    <v-dialog
      v-model="error"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 red--text">
          :(
        </v-card-title>

        <v-card-text>
          <p><b>Ой!</b> Тут произошла ошибка во время работы сайта.</p>
          <p>Код: <b>{{errorCode}}</b></p>
          <p>Обратитесь к Администрации веб-сайта для диагностики проблемы.</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="reload()"
          >
            Перезагрузка
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="heavyLoading" opacity="1" style="z-index: 9999">
      <v-progress-circular
          indeterminate
          size="64"
          color="primary"
          class="ma-auto d-block mb-4"
      ></v-progress-circular>
      <div>
        {{ funMessages[Math.floor(Math.random() * funMessages.length)] }}
      </div>
    </v-overlay>
    <v-progress-linear
        :active="loading"
        indeterminate
        absolute
        top
    ></v-progress-linear>
    <div v-if="!error">
      <navbar></navbar>
    <v-main>
      <router-view></router-view>
    </v-main>
    </div>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "App",
  components: {
    Navbar
  },
  methods: {
    reload() {
      window.location.reload();
    }
  },
  data: () => {
    return {
      loading: false,
      heavyLoading: true,
      error: false,
      errorCode: "",
      funMessages: [
        "Ждем у моря погоды...",
        "Кормим волков мясом...",
        "Ждем вайпа...",
        'Ожидаем //set 0 города "1"...',
        "Выполняем экспедицию на край света...",
        "Жди и я приду..."
      ]
    };
  },
  mounted() {
    this.$root.$on('new-error', (e) => {
      this.error = true;
      this.errorCode = e;
    })
    this.$nextTick(function() {
      let app = this;
      setTimeout(function() {
        app.heavyLoading = false;
      }, 500);
    });

    this.$router.beforeResolve((to, from, next) => {
      if (to.name) {
        this.loading = true;
      }
      next();
    });

    this.$router.afterEach(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    });
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;700&display=swap");

* {
  font-family: "Inter", sans-serif;
}

@for $i from 1 through 35 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}
</style>
