<template>
  <div class="home">
    <section
      class="background justify-center align-center"
      :style="{ 'background-image': `url(${require('@/assets/background-overlay.png')})` }"
    >
      <div style="max-width: 500px; text-align: center">
        <img
            :src="require('@/assets/logo.png')"
            alt="Лого :)"
            class="logo"
        />
        <header>Военнополитический сервер для ваших нужд</header>
        <p>
          Развивайтесь. Расширяйтесь. Воюйте.<br>
          Это - MineTown!
        </p>
        <p><b>На проекте сейчас играют <b>{{ players }}</b> игроков!</b></p>
      </div>
    </section>
    <v-container>
      <section class="cards">
        <div class="card flex-column-reverse flex-md-row">
          <div class="card-content">
            <header>Карта земли</header>
            <div>
              Абсолютный реализм.
              <p>Наш проект использует реальную карту земли, которая была перенесена в игру. Посетите страны СНГ, Европу, Америку и многие другие места! Выбирайте себе любое понравившееся место!</p>
            </div>
          </div>
          <img
              :src="require('@/assets/screens/map.png')"
              alt="Игроки"
              class="card-image"
          />
        </div>
        <div class="card flex-column-reverse flex-md-row-reverse">
          <div class="card-content">
            <header>Государства</header>
            <div>
              Начните с маленького провинциального города и закончите сильнейшей империей!
              <p>Мы используем знаменитый плагин на города "Towny", который позволяет создавать города и нации. Приглашайте друзей, развивайтесь и становитесь сильнейшим городом на проекте.</p>
            </div>
          </div>
          <img
              :src="require('@/assets/screens/towns.png')"
              alt="Игроки"
              class="card-image"
          />
        </div>
        <div class="card flex-column-reverse flex-md-row">
          <div class="card-content">
            <header>Экономика</header>
            <div>
              Игроки - одно из главных звеньев экономики проекта.
              <p>Мы за здоровую и управляемую экономику. Мы корректируем некоторые цены, а игрокам даем шанс по-своему управлять рынком. Хотите устроить монополию? Попробуйте.</p>
            </div>
          </div>
          <img
              :src="require('@/assets/screens/economy.png')"
              alt="Игроки"
              class="card-image"
          />
        </div>
        <div class="card flex-md-row-reverse flex-column-reverse justify-center justify-md-start">
          <div class="card-content">
            <header>Ролевая игра</header>
            <div>
              <i>*выпил кружку пива*</i>
              <p>Мы поддерживаем и продвигаем идею ролевой игры. Команды /me, /do, /cdo, /b (и другие) уже есть на серверах. Найдите себе компанию и устройте грандиозную отыгровку!</p>
            </div>
          </div>
          <img
              :src="require('@/assets/screens/rp.png')"
              alt="Игроки"
              class="card-image"
          />
        </div>
      </section>
    </v-container>
    <section class="advantages">
      <header class="text-center">Мы стараемся ради наших игроков</header>
      <p class="text-center mb-15">Список наших преимуществ</p>
      <div class="advantage-list flex-column flex-md-row">
        <a  class="advantage">
          <div class="advantage-icon">
            <v-icon large color="primary">mdi-chart-areaspline-variant</v-icon>
          </div>
          <div>
            <p class="advantage-header">Стабильность</p>
            <p class="advantage-text">
              Наш проект встретит вас хорошей стабильностью и периодичностью вайпов.
            </p>
          </div>
        </a>
        <a  class="advantage">
          <div class="advantage-icon">
            <v-icon large color="primary">mdi-star</v-icon>
          </div>
          <div>
            <p class="advantage-header">Уникальность</p>
            <p class="advantage-text">
              Команда старается над созданием уникального игрового контента для игроков.
            </p>
          </div>
        </a>
        <a  class="advantage">
          <div class="advantage-icon">
            <v-icon large color="primary">mdi-fit-to-page</v-icon>
          </div>
          <div>
            <p class="advantage-header">Non-stop Расширение</p>
            <p class="advantage-text">
              Наш проект постоянно растет и мы не собираемся останавливаться.
            </p>
          </div>
        </a>
        <a  class="advantage">
          <div class="advantage-icon">
            <v-icon large color="primary">mdi-shield-star</v-icon>
          </div>
          <div>
            <p class="advantage-header">Администрация</p>
            <p class="advantage-text">
              Мы очень кропотливо и внимательно относимся к отбору на Администрацию. Приколы не пройдут.
            </p>
          </div>
        </a>
        <a  class="advantage">
          <div class="advantage-icon">
            <v-icon large color="primary">mdi-currency-eur</v-icon>
          </div>
          <div>
            <p class="advantage-header">Экономика</p>
            <p class="advantage-text">
              Игроки - хозяева экономики. Мы лишь чуть-чуть корректируем её ;)
            </p>
          </div>
        </a>
        <a class="advantage">
          <div class="advantage-icon">
            <v-icon large color="primary">mdi-account-group</v-icon>
          </div>
          <div>
            <p class="advantage-header">Сообщество</p>
            <p class="advantage-text">
              У нас вы найдете себе лучшую компанию.
            </p>
          </div>
        </a>
      </div>
    </section>
    <section class="letsgo">
      <header>Чего вы ждете?</header>
      <p>Присоединяйтесь к нам и более <b>15,000</b> игрокам!</p>
      <v-btn color="dark" elevation="2" to="/join">Погнали!</v-btn>
    </section>
    <Footer></Footer>
    <scroll-fab></scroll-fab>
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import ScrollFab from '@/components/ScrollFab';
import axios from 'axios';
export default {
  name: "Home",
  components: {ScrollFab, Footer},
  data() {
    return {
      players: 0
    };
  },
  async beforeCreate() {
    let data = await axios.get(
      "https://api.minetools.eu/ping/mc.minetown.ru/25565"
    );
    this.players = data.data.players.online | 0;
  }
};
</script>

<style scoped>

.advantages {
  padding: 100px 0px;
  border-top: #393939 1px solid;
}

.advantages header {
  font-size: 30px;
  font-weight: bolder;
  margin-bottom: 15px;
}

.advantage {
  display: flex;
  width: 50%;
  align-items: center;
  text-decoration: none;
  margin-bottom: 10px;
}

.advantage-list {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  margin: 0 auto;
}

.advantage-header {
  margin-bottom: 3px;
}

.advantage-text {
  color: white;
}

.advantage-icon {
  background-color: #212121;
  padding: 16px;
  border-radius: 8px;
  display: inline-block;
  height: 68px;
  margin-right: 10px;
}

.letsgo {
  background: #FDC830;
  background: -webkit-linear-gradient(to right, #F37335, #FDC830);
  background: linear-gradient(to right, #F37335, #FDC830);
  padding: 30px 0;
  text-align: center;
}

.letsgo header {
  font-size: 35px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.letsgo p {
  font-size: 18px;
}

.background {
  display: flex;
  padding: 96px 0;
}

.logo {
  max-height: 128px;
}

.background header {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 32px;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.card {
  display: flex;
  max-width: 900px;
  align-items: center;
  justify-content: space-between;
}

.card-content div {
  max-width: 400px;
}

.card-content div p {
  margin: 6px 0;
}

.card-content {
  margin: 50px;
}

.card header {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-image {
  max-height: 230px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

@media screen and (max-width: 959px) {
  .advantage {
    width: 100%;
  }

  .advantage-list {
    width: 100%;
    padding: 0 15px;
  }

  .background header {
    font-size: 27px;
  }

  .background {
    padding: 30px 0;

  }
}
</style>
